<template>
  <v-card
    :loading="loading || localLoading"
    :disabled="loading || localLoading"
    :elevation="!(title || addtitle || returnTitle) ? '0' : undefined"
    class="pa-0"
  >
    <v-card-title v-if="title || addtitle || returnTitle">
      {{ title }}
      <v-spacer></v-spacer>
      <template>
        <slot name="headerTag"></slot>
      </template>
      <v-btn color="success" v-if="addpageRoute" :to="addpageRoute">
        <v-icon>mdi-plus</v-icon>
        {{ addtitle }}
      </v-btn>
      <v-btn
        color="black"
        outlined
        class="mr-2"
        v-if="returnPageRoute"
        :to="returnPageRoute"
      >
        {{ returnTitle }}
      </v-btn>
    </v-card-title>
    <v-card-subtitle v-if="subtitle">
      {{ subtitle }}
    </v-card-subtitle>
    <slot name="topContentTag" />
    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="itemsPerPage"
      :search="search"
      :group-by="groupBy"
      :show-group-by="isShowGroup"
      :show-select="isShowSelect"
      :single-select="singleSelect"
      :item-key="itemKey"
      v-model="selected"
      loading-text="در حال بارگزاری... منتظر بمانید"
      hide-default-footer
    >
      <template v-slot:top>
        <slot name="topActions"></slot>
      </template>
      <template slot="no-data">
        <div>هیچ اطلاعاتی ثبت نشده است</div>
      </template>
      <template v-if="items.length > 0" v-slot:body="{ items }">
        <tbody>
          <tr v-for="(row, i) in items" :key="i">
            <td v-if="isShowSelect">
              <v-checkbox
                v-model="selected"
                :value="row"
                style="margin:0px;padding:0px"
                hide-details
              />
            </td>

            <slot name="contentTag" :row="row">
              <td>{{ row.id }}</td>
            </slot>

            <td v-if="hasButton">
              <slot name="editButtonTag" :row="row">
                <v-btn
                  class="mx-1"
                  dark
                  small
                  v-if="isEditEnabled"
                  color="primary"
                  @click="onEditClick(row)"
                >
                  <v-icon small dark>mdi-pen</v-icon>

                  {{ editText }}
                </v-btn>
              </slot>

              <slot name="middleButtonTag" :row="row"></slot>

              <slot name="removeButtonTag" :row="row">
                <v-btn
                  class="mx-1"
                  dark
                  small
                  v-if="isRemoveEnabled"
                  color="error"
                  @click="checkDeleteConfirmation(row)"
                >
                  <v-icon small dark>mdi-delete</v-icon>
                  {{ deleteText }}
                </v-btn>
              </slot>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
    <MyDialog ref="dialogRef" @onConfirm="onDeleteClick" />
    <div class="text-center mt-8 pb-2">
      <!-- <v-pagination
        v-if="pagesLength && paginationEnabled"
        v-model="page"
        :length="pagesLength"
        @input="handlePageChange"
      ></v-pagination> -->
      <my-paginate
        v-if="pagesLength && paginationEnabled"
        v-model="page"
        :length="pagesLength"
        @onChange="handlePageChange"
      />
    </div>
  </v-card>
</template>

<script>
import MyDialog from "./MyDialog";
import MyAxios from "@/constants/MyAxios";
import MyPaginate from "./MyPaginate.vue";

export default {
  data() {
    return {
      page: +this.$route?.query?.page || 1,
      selected: [],
      localLoading: false,
    };
  },
  components: {
    MyDialog,
    MyPaginate,
  },
  props: {
    headers: {
      required: true,
      type: Array,
    },
    items: {
      required: true,
      type: Array,
    },
    itemsPerPage: {
      type: Number,
      default: 15,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    paginationEnabled: {
      type: Boolean,
      default: true,
    },
    pagesLength: {
      type: Number,
      default: 1,
    },
    title: {
      type: String,
    },
    subtitle: {
      type: String,
      default: "",
    },
    addtitle: {
      required: false,
    },
    addpageRoute: {
      required: false,
    },
    returnTitle: {
      required: false,
    },
    returnPageRoute: {
      required: false,
    },
    search: {
      required: false,
    },
    path: {
      required: false,
    },
    pathParams: {
      required: false,
    },
    isShowGroup: {
      required: false,
      default: false,
      type: Boolean,
    },
    isEditEnabled: {
      required: false,
      default: true,
      type: Boolean,
    },
    isRemoveEnabled: {
      required: false,
      default: true,
      type: Boolean,
    },
    groupBy: {
      required: false,
    },
    isShowSelect: {
      required: false,
      default: false,
      type: Boolean,
    },
    singleSelect: {
      required: false,
      default: false,
      type: Boolean,
    },
    itemKey: {
      required: false,
    },
    deleteText: {
      required: false,
      default: "حذف",
      type: String,
    },
    editText: {
      required: false,
      default: "ویرایش",
      type: String,
    },
    hasButton: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    getItems() {
      this.localLoading = true;
      MyAxios.get(this.path, {
        params: {
          page: this.page,
          ...this.pathParams,
        },
      })
        .then((response) => {
          this.$emit("update-items", response.data.data);
          this.localLoading = false;
        })
        .catch((error) => {
          this.localLoading = false;
          console.log("update-items", error.response);
        });
    },
    onEditClick(item) {
      this.$emit("onEditClick", item);
    },
    checkDeleteConfirmation(item) {
      if (item?.name) {
        this.$refs.dialogRef.show(
          "ایا مایل به حذف '" + item.name + "' هستید؟",
          item
        );
      } else if (item?.title) {
        this.$refs.dialogRef.show(
          "ایا مایل به حذف '" + item.title + "' هستید؟",
          item
        );
      } else {
        this.$refs.dialogRef.show("ایا مایل به حذف هستید؟", item);
      }
    },
    handlePageChange(value) {
      this.page = value;
      this.$router.replace({
        path: this.$route.path,
        query: { ...this.$route.query, page: value },
      });
      this.path && this.getItems();
      this.$emit("onPageChanged");
    },
    onDeleteClick(item) {
      this.$emit("onDeleteClick", item);
    },
  },
};
</script>

<style></style>
